@import url("https://fonts.googleapis.com/css2?family=Itim&family=Merienda:wght@700&display=swap");
.Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto 0 auto;
  width: 360px;
}
.ant-progress-steps-item.ant-progress-steps-item-active {
  background-color: #0050b3;
}
.ant-progress-steps-item {
  margin: 6px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.FlagMine {
  font-family: "Merienda", Helvetica, sans-serif;
  font-size: 17px;
  color: #f5f5f5;
}
.Bomb {
  animation: blink 1s linear infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.BombCount {
  color: #a8071a;
}
.DivideBomb {
  font-size: 21px;
  color: #0c0c0c;
  font-weight: bold;
}
