@import url(https://fonts.googleapis.com/css2?family=Itim&family=Merienda:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #20232b;
  widows: 100%;
  min-height: 94vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px auto 0 auto;
  width: 360px;
}
.ant-progress-steps-item.ant-progress-steps-item-active {
  background-color: #0050b3;
}
.ant-progress-steps-item {
  margin: 6px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.FlagMine {
  font-family: "Merienda", Helvetica, sans-serif;
  font-size: 17px;
  color: #f5f5f5;
}
.Bomb {
  animation: blink 1s linear infinite;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
.BombCount {
  color: #a8071a;
}
.DivideBomb {
  font-size: 21px;
  color: #0c0c0c;
  font-weight: bold;
}

